import { Box, Button, Typography } from '@mui/material'
import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet-async'
import { LuArrowRightCircle } from 'react-icons/lu'
import { useNavigate } from 'react-router-dom'

const FourOhFour = ({ isAdmin }) => {
  const navigate = useNavigate()

  return (
    <Fragment>
      <Helmet>
        <title>404 | {process.env.REACT_APP_APP_NAME}</title>
      </Helmet>
      <Box
        sx={{
          display: 'flex',
          position: 'absolute',
          inset: 0,
          justifyContent: 'center',
          alignItems: 'center',
          // height: '100%',
          // minHeight: '100vh',

          flexDirection: 'column',
          paddingX: 5,
        }}
      >
        <Typography
          variant="h1"
          sx={{ fontSize: { md: '350px', xs: '60px', sm: '120px' } }}
          color="primary"
        >
          404
        </Typography>
        <Typography variant="h4" color="common.white">
          Something went wrong
        </Typography>
        <Typography
          variant="body1"
          color="#9c9c9c"
          sx={{ mt: 1, textAlign: 'center', fontWeight: 500 }}
        >
          The page you are looking for was moved, removed, renamed or might
          never existed!
        </Typography>
        <Button
          variant="contained"
          sx={{ mt: 2 }}
          onClick={() => {
            if (isAdmin) {
              navigate('/admin')
            } else {
              navigate('/login')
            }
          }}
          color="secondary"
          endIcon={<LuArrowRightCircle size={30} color="#FFFFFF" />}
        >
          Homepage
        </Button>
      </Box>
    </Fragment>
  )
}

export default FourOhFour
