import React, { Suspense, useEffect, useRef, useState } from 'react'
import { AuthContextProvider } from './context/AuthContext'
import ThemeProvider from './theme'
import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import ScrollToTop from './components/ScrollToTop'
import Routes from './routes/Routes'
import { styled } from '@mui/material'
import { SnackbarProvider } from 'notistack'
import Loader from './components/Loader'
import { AdminAuthContextProvider } from './context/AdminAuthContext'
import { FilterContextProvider } from './context/FilterContext'
import { ChatCountContextProvider } from './context/ChatCountContext'
import { requestPermission } from './firebase/firebase'

// alert notification
const StyledSnackbarProvider = styled(SnackbarProvider)`
  &.notistack-MuiContent-success {
    background-color: #ddab2e;
    color: #ffffff;
  }
  ,
  &.notistack-MuiContent-error {
    background-color: #ff1e1e;
  }
  ,
  &.notistack-MuiContent {
    box-shadow: 0px 3px 2px -2px rgba(0, 0, 0, 0.06),
      0px 5px 3px -2px rgba(0, 0, 0, 0.02);
    font-weight: 500;
    border-radius: 12px;
  }
`

const App = () => {
  const btnRef = useRef(null)

  useEffect(() => {
    const timer = setTimeout(() => {
      const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent)
      const isAndroid = /Android/i.test(navigator.userAgent)

      if (isIOS || /apple/i.test(navigator.vendor)) {
        requestPermission()
      } else if (
        isAndroid ||
        (!isIOS && localStorage.getItem('token') && btnRef.current)
      ) {
        btnRef.current.click()
      }
    }, 1000) // Wait for 1 second before auto-clicking the button

    // Add protection against developer tools
    const protectAgainstDevTools = () => {
      // Detect if developer tools are open
      const devToolsOpen = () => {
        const widthThreshold = window.outerWidth - window.innerWidth > 160
        const heightThreshold = window.outerHeight - window.innerHeight > 160
        return widthThreshold || heightThreshold
      }

      // Function to be called when dev tools are detected
      const handleDevToolsOpen = () => {
        // You can customize this action (e.g., redirect, show a message, etc.)
        alert('Developer tools are not allowed on this site.')
        window.location.href = 'about:blank' // Redirect to a blank page
      }

      // Check periodically
      setInterval(() => {
        if (devToolsOpen()) {
          handleDevToolsOpen()
        }
      }, 1000)

      // Disable right-click
      document.addEventListener('contextmenu', (e) => e.preventDefault())

      // Disable some keyboard shortcuts
      document.addEventListener('keydown', (e) => {
        if (
          // Ctrl+Shift+I (Windows) or Cmd+Option+I (Mac)
          (e.ctrlKey && e.shiftKey && e.keyCode === 73) ||
          (e.metaKey && e.altKey && e.keyCode === 73) ||
          // F12
          e.keyCode === 123
        ) {
          e.preventDefault()
        }
      })
    }

    protectAgainstDevTools()

    return () => clearTimeout(timer)
  }, [localStorage.getItem('token'), btnRef])

  return (
    <>
      <AuthContextProvider>
        <AdminAuthContextProvider>
          <FilterContextProvider>
            <ChatCountContextProvider>
              <BrowserRouter>
                <HelmetProvider>
                  <ThemeProvider>
                    <ScrollToTop />
                    <StyledSnackbarProvider
                      hideIconVariant
                      autoHideDuration={3000}
                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                      <Suspense fallback={<Loader />}>
                        <Routes />
                      </Suspense>
                    </StyledSnackbarProvider>
                  </ThemeProvider>
                </HelmetProvider>
              </BrowserRouter>
            </ChatCountContextProvider>
          </FilterContextProvider>
        </AdminAuthContextProvider>
      </AuthContextProvider>
      <button
        onClick={() => {
          requestPermission()
        }}
        ref={btnRef}
        style={{ position: 'fixed', visibility: 'hidden' }}
      >
        Enable Notification
      </button>
    </>
  )
}

export default App
